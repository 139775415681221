import React, { useCallback, useEffect, useState } from "react";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column";
import nda from "src/images/nda.png";

const AmazonConfidentialityAgreementModal = () => {
  const [isUserAgreed, setIsUserAgreed] = useState(false);
  const [isScrolledOnce, setIsScrolledOnce] = useState(true);
  const [isNDALoaded, setIsNDALoaded] = useState(false);

  const handleScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    const { scrollHeight, clientHeight, scrollTop } = target;
    const isScrolledToBottom = !(scrollHeight - clientHeight - 1 > scrollTop);
    if (isScrolledToBottom) {
      setIsScrolledOnce(true);
    }
  }, []);

  const handleUserAgreement = () => {
    localStorage.setItem("userAgreement", "true");
    setIsUserAgreed(true);
    window.location.reload();
  };

  const handleImgLoad = () => {
    setIsNDALoaded(true);
  };

  return (
    <>
      {!isUserAgreed && !localStorage.getItem("userAgreement") && (
        <div id="nda-modal-container" onScroll={handleScroll}>
          <Modal
            title={
              "Amazon Confidentiality Agreement (Please scroll to the bottom to review and agree)"
            }
            open={true}
            width="700px"
            scrollContainer="modal"
          >
            <Column height="250px">
              <img src={nda} onLoad={() => handleImgLoad()} />
            </Column>
            <ModalFooter>
              <Row widths="fill">
                {isNDALoaded && (
                  <Button
                    id="nda-modal-save-btn"
                    type="primary"
                    size="small"
                    onClick={handleUserAgreement}
                    disabled={!isScrolledOnce}
                  >
                    I Agree
                  </Button>
                )}
              </Row>
            </ModalFooter>
          </Modal>
        </div>
      )}
    </>
  );
};

export default AmazonConfidentialityAgreementModal;
