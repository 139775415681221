import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "src/store/store";
import Box from "@amzn/meridian/box";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import Heading from "@amzn/meridian/heading";
import Button from "@amzn/meridian/button";
import { getUser } from "src/store/userSlice";
import {
  getEmailSettingsForSite,
  saveEmailSettings,
  setPageDisabled,
} from "src/store/emailDistributionPageSlice";
import { NO_SITE } from "src/store/siteSlice";
import "./emailDistros.scss";
import EmailDistributionFormFields from "./emailDistributionFormFields/emailDistributionFormFields";
import LoadingSpinner from "src/components/shared/loadingSpinner/loadingSpinner";
import Text from "@amzn/meridian/text";

const emailDistros = () => {
  const dispatch = useAppDispatch();
  const { emailSettingsFormData, pageDisabled, isLoading } = useAppSelector(
    (state) => state.emailDistributionPage
  );
  const { userLoading } = useAppSelector((state) => state.user);
  const { selectedSite } = useAppSelector((state) => state.sites);

  useEffect(() => {
    if (selectedSite !== NO_SITE) {
      dispatch(getEmailSettingsForSite({ siteId: selectedSite.id }));
    }
  }, [selectedSite]);

  const toggleEditMode = () => {
    dispatch(setPageDisabled(!pageDisabled));
  };

  const cancel = () => {
    if (selectedSite !== NO_SITE) {
      dispatch(getEmailSettingsForSite({ siteId: selectedSite.id }));
    }
    toggleEditMode();
  };

  const save = () => {
    if (selectedSite !== NO_SITE) {
      dispatch(
        saveEmailSettings({ ...emailSettingsFormData, siteId: selectedSite.id })
      );
      dispatch(getEmailSettingsForSite({ siteId: selectedSite.id }));
      toggleEditMode();
    }
  };

  return (
    <Column className="email-settings__content">
      <Heading level={1} type="h500" className="update-shifts__page-heading">
        Update Default Emails
      </Heading>
      <Heading level={3}>Email Distros for {selectedSite?.site}</Heading>
      <Text>
        Here you update your site's default emails for accommodation related
        communications
      </Text>
      <Box type="outline" spacingInset="500 400">
        {!isLoading ? <EmailDistributionFormFields /> : <LoadingSpinner />}
      </Box>
      {!isLoading && (
        <Row alignmentHorizontal="end">
          {pageDisabled && (
            <Button
              minWidth={100}
              onClick={toggleEditMode}
              type="primary"
              data-cy={"edit-default-emails__btn"}
            >
              Edit
            </Button>
          )}
          {!pageDisabled && (
            <Button minWidth={100} onClick={cancel} type="secondary">
              Cancel
            </Button>
          )}
          {!pageDisabled && (
            <Button minWidth={100} onClick={save} type="primary" data-cy="default-email-save__btb">
              Save
            </Button>
          )}
        </Row>
      )}
    </Column>
  );
};

export default emailDistros;
