import React from "react";
import Row from "@amzn/meridian/row";
import Text from "@amzn/meridian/text";
import Checkbox from "@amzn/meridian/checkbox";
import Input from "@amzn/meridian/input";
import Column from "@amzn/meridian/column";
import { useAppSelector } from "src/store/store";
import { setEmailSettingsFormData } from "src/store/emailDistributionPageSlice";
import { useDispatch } from "react-redux";


const emailDistributionFormFields = () => {
  const dispatch = useDispatch();

  const { emailSettingsFormData, pageDisabled, emailLength } = useAppSelector((state) => state.emailDistributionPage);

  return (
    <Column spacing="500">
      <Row widths={["grid-3", "grid-8"]} alignmentVertical="baseline">
        <Text
          tag="label"
          type="b300"
          color="primary"
          htmlFor="rejected-placement-email-recipients-input"
        >
          Email distros for 'Rejected Placement'.
        </Text>
        <Column>
          <Input
            value={emailSettingsFormData?.deniedEmailRecipients}
            onChange={(deniedEmailRecipients) => {
              dispatch(
                setEmailSettingsFormData({
                  ...emailSettingsFormData,
                  deniedEmailRecipients,
                })
              );
            }}
            type="text"
            placeholder="Enter ; separated list of aliases or email addresses here"
            disabled={pageDisabled}
            id="rejected-placement-email-recipients-input"
            data-cy="rejected-placement-email-recipients__input"
            constraintText={`${emailSettingsFormData.deniedEmailRecipients.length} / ${emailLength} characters`}
          />

          <Checkbox
            checked={emailSettingsFormData.supervisorsForDeniedEmail || false}
            onChange={(supervisorsForDeniedEmail) => {
              dispatch(
                setEmailSettingsFormData({
                  ...emailSettingsFormData,
                  supervisorsForDeniedEmail,
                })
              );
            }}
            disabled={pageDisabled}
            data-cy="auto-include-aa-supervisor-rejected__checkbox"
          >
            Auto-include AA's supervisor?
          </Checkbox>
        </Column>
      </Row>
      <Row widths={["grid-3", "grid-8"]} alignmentVertical="baseline">
        <Text
          tag="label"
          type="b300"
          color="primary"
          htmlFor="approved-email-recipients"
        >
          Email distros for 'Approved Onsite Placement'
        </Text>
        <Column>
          <Input
            value={emailSettingsFormData?.placementEmailRecipients}
            onChange={(placementEmailRecipients) => {
              dispatch(
                setEmailSettingsFormData({
                  ...emailSettingsFormData,
                  placementEmailRecipients,
                })
              );
            }}
            type="text"
            placeholder="Enter ; separated list of aliases or email addresses here"
            disabled={pageDisabled}
            id="approved-email-recipients"
            data-cy="approved-email-recipients__input"
            constraintText={`${emailSettingsFormData.placementEmailRecipients.length} / ${emailLength} characters`}
          />
          <Checkbox
            checked={
              emailSettingsFormData.supervisorsForPlacementEmail || false
            }
            onChange={(supervisorsForPlacementEmail) => {
              dispatch(
                setEmailSettingsFormData({
                  ...emailSettingsFormData,
                  supervisorsForPlacementEmail,
                })
              );
            }}
            disabled={pageDisabled}
            data-cy="auto-include-aa-supervisor-approved__checkbox"
          >
            Auto-include AA's supervisor?
          </Checkbox>
        </Column>
      </Row>
      <Row widths={["grid-3", "grid-8"]} alignmentVertical="baseline">
        <Text
          tag="label"
          type="b300"
          color="primary"
          htmlFor="loa-email-recipients"
        >
          Email distros for 'No Onsite Placement LOA'.
        </Text>
        <Column>
          <Input
            value={emailSettingsFormData?.loaEmailRecipients}
            onChange={(loaEmailRecipients) => {
              dispatch(
                setEmailSettingsFormData({
                  ...emailSettingsFormData,
                  loaEmailRecipients,
                })
              );
            }}
            type="text"
            placeholder="Enter ; separated list of aliases or email addresses here"
            disabled={pageDisabled}
            id="loa-email-recipients"
            data-cy="loa-email-recipients__input"
            constraintText={`${emailSettingsFormData.loaEmailRecipients.length} / ${emailLength} characters`}
          />
          <Checkbox
            checked={emailSettingsFormData?.supervisorsForLoaEmail || false}
            onChange={(supervisorsForLoaEmail) => {
              dispatch(
                setEmailSettingsFormData({
                  ...emailSettingsFormData,
                  supervisorsForLoaEmail,
                })
              );
            }}
            disabled={pageDisabled}
            data-cy="auto-include-aa-supervisor-loa__checkbox"
          >
            Auto-include AA's supervisor?
          </Checkbox>
        </Column>
      </Row>
      <Row widths={["grid-3", "grid-8"]} alignmentVertical="baseline">
        <Text
          tag="label"
          type="b300"
          color="primary"
          htmlFor="rtfd-email-recipients"
        >
          Email distros for 'Returned to Full Duty'.
        </Text>
        <Column>
          <Input
            value={emailSettingsFormData?.rtfdEmailRecipients}
            onChange={(rtfdEmailRecipients) => {
              dispatch(
                setEmailSettingsFormData({
                  ...emailSettingsFormData,
                  rtfdEmailRecipients,
                })
              );
            }}
            type="text"
            placeholder="Enter ; separated list of aliases or email addresses here"
            disabled={pageDisabled}
            id="rtfd-email-recipients"
            data-cy="rtfd-email-recipients__input"
            constraintText={`${emailSettingsFormData.rtfdEmailRecipients.length} / ${emailLength} characters`}
          />
          <Checkbox
            checked={emailSettingsFormData.supervisorsForRtfdEmail || false}
            onChange={(supervisorsForRtfdEmail) => {
              dispatch(
                setEmailSettingsFormData({
                  ...emailSettingsFormData,
                  supervisorsForRtfdEmail,
                })
              );
            }}
            disabled={pageDisabled}
            data-cy="auto-include-aa-supervisor-rtfd__checkbox"
          >
            Auto-include AA's supervisor?
          </Checkbox>
        </Column>
      </Row>
    </Column>
  );
}

export default emailDistributionFormFields;



